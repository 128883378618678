import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Plan } from 'interfaces/plan';
import { User } from 'interfaces/User';
import { useDialog } from 'components/Dialog';
import ChangePaymentConfirm from 'modules/reactvate-suspended/ChangePaymentConfirm';
import { PartnerOrNull } from '../../typings';
import getUserProfile from '../Profile/services/getUserProfile';
import OfferedPlansList from './components/OfferedPlansList';
import OfferedPlansListPlaceholder from './components/OfferedPlansListPlaceholder';
import SubscriptionsList from './components/SubscriptionsList';
import SubscriptionsListPlaceholder from './components/SubscriptionsListPlaceholder';
import Context from './context';
import { getPlans } from './services/getPlans';
import getUserPlans from './services/getUserPlans';

const titleTypographyProps = { color: 'grey.100', fontFamily: 'primary' };
const descriptionTypographyProps = {
  color: 'grey.300',
  fontFamily: 'secondary',
  fontSize: 14,
  mt: 3,
};

const getPartnersTiedToPlan = (plans: Plan[]) => {
  const allPartners = plans?.map((plan) => plan.partners)?.flat?.();
  const partners = allPartners?.filter(
    (partner, index, self) =>
      index ===
      self.findIndex(
        (currentPartner) => currentPartner?.code === partner?.code,
      ),
  );
  return partners;
};

const Subscriptions = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [userPlans, setUserPlans] = useState<Plan[]>([]);
  const [user, setUser] = useState<User>();
  const dialog = useDialog();
  const { isLoading: isLoadingPlans } = useQuery('plans', getPlans, {
    onSuccess: ({ data }) => setPlans(data as Plan[]),
  });
  const { isLoading: isLoadingSubscriptions } = useQuery(
    'userPlans',
    getUserPlans,
    { onSuccess: ({ data }) => setUserPlans(data as Plan[]) },
  );
  const { isLoading: isLoadingUser } = useQuery('user', getUserProfile, {
    onSuccess: ({ data }) => setUser(data as User),
  });
  const [planType, setPlanType] = useState('default');
  const [partner, setPartner] = useState('');
  const isButtonSelected = (type: string) => planType === type;
  const togglePlanType = (type: string) => setPlanType(type);
  const sortByPartnerHandler = (evt: SelectChangeEvent) =>
    setPartner(evt.target.value);
  const plansWithPartners = plans.filter((plan) => plan.partners?.length > 0);
  const havePlansWithPartners = plansWithPartners?.length > 0;
  const typePlanIsPartners = planType === 'partners';
  const removePlanAlreadyObtained = plans.filter((plan) => {
    const subscription = userPlans.some(
      (currentSubscription: any) => currentSubscription.id === plan.id,
    );
    return !subscription;
  });
  const filteredPlans = typePlanIsPartners
    ? removePlanAlreadyObtained.filter((offer) => offer.type === 'partner')
    : removePlanAlreadyObtained.filter((offer) => offer.type !== 'partner');
  const filteredPlansByPartner = typePlanIsPartners
    ? filteredPlans.filter((plan) => {
        const containsPartner = plan?.partners?.some(
          (currentPartner) => currentPartner.code === partner,
        );
        return containsPartner;
      })
    : filteredPlans;
  const getPartner = typePlanIsPartners
    ? getPartnersTiedToPlan(filteredPlans)!.find(
        (currentPartner) => currentPartner?.code === partner,
      )
    : null;
  const hasSuspendedPlans = userPlans.some(
    (currentPlan) => currentPlan.status === 'suspended',
  );
  useEffect(() => {
    if (typePlanIsPartners) {
      const firstPartner = getPartnersTiedToPlan(filteredPlans)[0]?.code;
      setPartner(firstPartner as string);
    }
  }, [planType]);
  const openChangePaymentModal = async () => {
    await dialog.open({
      element: <ChangePaymentConfirm userSubscriptions={userPlans} />,
      sx: { bgcolor: 'common.white', maxWidth: 375, width: '100%' },
    });
  };
  return (
    <Context.Provider
      value={{ getPartner, plans, setPlans, userPlans, setUserPlans }}
    >
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ flex: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ flex: 1 }}
          >
            <Stack sx={{ flex: 1 }}>
              <Typography sx={titleTypographyProps}>
                Minhas assinaturas
              </Typography>
              <Typography sx={descriptionTypographyProps}>
                Todos os planos que fazem parte da sua assinatura
              </Typography>
            </Stack>
            {hasSuspendedPlans && (
              <Button onClick={openChangePaymentModal}>
                Atualizar planos suspensos
              </Button>
            )}
          </Stack>
          {isLoadingSubscriptions ? (
            <SubscriptionsListPlaceholder />
          ) : (
            <SubscriptionsList />
          )}
        </Stack>
        <Stack sx={{ flex: 1, mt: 5, pt: 4 }}>
          <Stack
            alignItems={{ xs: 'flex-start', lg: 'center' }}
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent={{ lg: 'space-between' }}
          >
            <Box>
              <Typography sx={titleTypographyProps}>Assine também</Typography>
              <Typography sx={descriptionTypographyProps}>
                Adicione outros planos na sua assinatura
              </Typography>
            </Box>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ gap: 3, mt: { xs: 3, lg: 0 } }}
            >
              {havePlansWithPartners && (
                <>
                  <Button
                    sx={{
                      bgcolor: isButtonSelected('default')
                        ? 'secondary.main'
                        : 'grey.600',
                      fontSize: 12,
                      height: 26,
                    }}
                    onClick={() => togglePlanType('default')}
                  >
                    Planos gerais
                  </Button>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ bgcolor: 'grey.600' }}
                  />
                  <Button
                    data-testid="filterByPartnersButton"
                    sx={{
                      bgcolor: isButtonSelected('partners')
                        ? 'secondary.main'
                        : 'grey.600',
                      fontSize: 12,
                      height: 26,
                    }}
                    onClick={() => togglePlanType('partners')}
                  >
                    Parceiros
                  </Button>
                  {typePlanIsPartners && (
                    <>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ bgcolor: 'grey.600' }}
                      />
                      <Select
                        value={partner}
                        sx={{ height: 26, minWidth: 161 }}
                        onChange={sortByPartnerHandler}
                      >
                        {getPartnersTiedToPlan(filteredPlans).map(
                          (currentPartner) => (
                            <MenuItem
                              value={currentPartner?.code}
                              sx={{
                                bgcolor: 'common.white',
                                fontFamily: 'secondary',
                                '&.Mui-selected': {
                                  ':hover': { bgcolor: 'common.white' },
                                  bgcolor: 'common.white',
                                },
                                ':hover': { bgcolor: 'common.white' },
                              }}
                            >
                              {currentPartner?.description}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack>
          {isLoadingPlans || isLoadingUser ? (
            <OfferedPlansListPlaceholder />
          ) : (
            filteredPlansByPartner.length && (
              <OfferedPlansList
                offers={filteredPlansByPartner}
                partner={getPartner as PartnerOrNull}
                user={user!}
              />
            )
          )}
        </Stack>
      </Stack>
    </Context.Provider>
  );
};

export default Subscriptions;
