import { Discount } from './Discount';
import { Plan } from './plan';

export enum DealStatus {
  activation = 'activation',
  reactivation = 'reactivation',
  renewal = 'renewal',
  reactivationPreCanceled = 'reactivation-pre-canceled',
}

export enum PaymentMethod {
  credit = 'credit',
  debit = 'debit',
  pix = 'pix',
  ame = 'ame',
  free = 'free',
}

export interface PaymentHistory {
  paymentMethod: PaymentMethod;
}

export interface BillingHistory {
  dealStatus: DealStatus;
  plan: Plan;
  discounts: Discount[];
  createdAt: Date;
  netValue: number;
  paymentHistory: PaymentHistory;
}
