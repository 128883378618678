import { Divider, Grid, Stack, Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { PaymentMethod } from 'interfaces/BillingHistory';
import { Subscription } from 'interfaces/Subscription';
import { formatDate } from 'utils/date';
import creditCard from '../../../../../assets/png/creditCard.png';
import pix from '../../../../../assets/png/pix.png';

type SubscriptionCardModalProps = {
  subscription: Subscription;
};

type LabelValueProps = {
  label: string;
  labelSx?: SxProps<Theme>;
  value: string;
  valueSx?: SxProps<Theme>;
};

const LabelValue = ({ label, labelSx, value, valueSx }: LabelValueProps) => (
  <>
    <Typography
      sx={{
        color: 'primary.light',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '16px',
        ...labelSx,
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        color: 'primary.light',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '16px',
        ...valueSx,
      }}
    >
      {value}
    </Typography>
  </>
);

const SubscriptionCardModal = ({
  subscription,
}: SubscriptionCardModalProps) => {
  const paymentTypeIsCreditCard =
    subscription.paymentMethod === PaymentMethod.credit;
  return (
    <Stack alignItems="center" sx={{ mb: 3 }}>
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        {subscription.title}
      </Typography>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          lineHeight: '16px',
          mt: 4,
          textAlign: 'center',
        }}
      >
        {subscription.description}
      </Typography>
      <Divider sx={{ mt: 5, width: '100%' }} />
      <Grid container sx={{ my: 3 }}>
        <Grid item xs={2}>
          <img
            src={paymentTypeIsCreditCard ? creditCard : pix}
            alt="credit card"
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column">
            <LabelValue
              label="Método de pagamento"
              labelSx={{
                color: 'primary.light',
                fontFamily: 'secondary',
                fontSize: 10,
                lineHeight: '16px',
                textTransform: 'uppercase',
              }}
              value={`${paymentTypeIsCreditCard ? 'Crédito' : 'Pix'}`}
              valueSx={{
                color: 'grey.400',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '16px',
                mt: 1,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <LabelValue
              label="Assinado em"
              labelSx={{
                color: 'primary.light',
                fontFamily: 'secondary',
                fontSize: 10,
                lineHeight: '16px',
                textTransform: 'uppercase',
              }}
              value={formatDate(subscription.createdAt, "dd/MM/yyyy'")}
              valueSx={{
                color: 'grey.400',
                fontFamily: 'secondary',
                fontSize: 14,
                lineHeight: '16px',
                mt: 1,
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%' }} />
      <Stack gap={3} sx={{ mt: 5, width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <LabelValue
            label="Valor original do plano"
            labelSx={{ fontFamily: 'secondary' }}
            value={subscription.price.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            valueSx={{ fontFamily: 'secondary' }}
          />
        </Stack>
        {subscription.discounts?.map((currentDiscount) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <LabelValue
              label={currentDiscount.title}
              value={`-${currentDiscount.price.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}`}
              valueSx={{ color: 'secondary.dark', fontFamily: 'secondary' }}
            />
          </Stack>
        ))}
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <LabelValue
            label="Total mensal:"
            labelSx={{
              color: 'grey.500',
              fontFamily: 'primary',
              fontSize: 16,
            }}
            value={subscription.netValue.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            valueSx={{
              color: 'secondary.dark',
              fontFamily: 'primary',
              fontSize: 16,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SubscriptionCardModal;
