import {
  Accordion,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useDialog } from 'components/Dialog';
import { Discount } from 'interfaces/Discount';
import { Plan } from 'interfaces/plan';
import { UserProfile } from 'modules/account/typings';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { goal } from '../assets';
import { acceptRententionDiscount } from '../services';
import AccordeonItems from './AccordeonItems';

interface RetentionDetailsProps {
  card: any;
  currentDiscount: Discount;
  navigate: any;
  profile?: UserProfile;
  subscription?: Plan;
  addPlanToDiscountSet: () => void;
}

const RetentionDetails = ({
  card,
  currentDiscount,
  navigate,
  profile,
  subscription,
  addPlanToDiscountSet,
}: RetentionDetailsProps) => {
  const [accordeonExpanded, setAccordeonExpanded] = useState(true);
  const alert = useAlert();
  const dialog = useDialog();
  const acceptDiscountHandler = async () => {
    addPlanToDiscountSet();
    try {
      await acceptRententionDiscount({
        userId: profile!.id,
        planId: subscription!.id,
        discountId: currentDiscount.id,
      });
      dialog.close();
      alert.success('Plano renovado com sucesso!');
      navigate();
    } catch {
      dialog.close();
      alert.error('Ops algo deu errado...');
    }
  };
  const assignHandler = async () => {
    await dialog.open({
      element: (
        <Stack
          data-testid="resubscribeSubscriptionModal"
          alignItems="center"
          justifyContent="space-around"
          gap={3}
        >
          <img
            src={goal}
            alt="Cartão vermelho"
            style={{ height: 82, width: 76 }}
          />
          <Typography sx={{ color: 'grey.500', fontSize: 20 }}>
            Bem vindo de volta
          </Typography>
          <Typography
            sx={{ color: 'grey.400', fontSize: 14, textAlign: 'center' }}
          >
            Seu desconto foi aplicado com sucesso,continue <br />
            aproveitando o melhor do futebol Paulista.
          </Typography>
          <Button
            data-testid="leaveButton"
            fullWidth
            sx={{ height: 60 }}
            onClick={acceptDiscountHandler}
          >
            Continuar
          </Button>
        </Stack>
      ),
      sx: {
        bgcolor: 'common.white',
        maxWidth: 470,
        width: '100%',
      },
    });
  };
  const getLocalePrice = (value?: number) => {
    const localePrice = value?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return localePrice;
  };
  const getPlanPeriodName = () => {
    if (subscription?.type === 'monthly') {
      return 'Renovação mensal';
    }
    return 'Renovação anual';
  };
  const getDiscountLabel = () => {
    if (currentDiscount.valueType === 'value') {
      const priceWithDiscounts = subscription!.price - currentDiscount.price;
      const finalPrice = priceWithDiscounts > 0 ? priceWithDiscounts : 0.0;
      return {
        label: `Desconto de ${getLocalePrice(currentDiscount.price)}`,
        discountValue: `- ${getLocalePrice(currentDiscount.price)}`,
        finalValue: getLocalePrice(finalPrice),
      };
    }
    const percentageValue = (subscription!.price * currentDiscount.price) / 100;
    const priceWithDiscounts = subscription!.price - percentageValue;
    const finalPrice = priceWithDiscounts > 0 ? priceWithDiscounts : 0.0;
    return {
      label: `Desconto de ${currentDiscount.price}%`,
      discountValue: `- ${getLocalePrice(percentageValue)}`,
      finalValue: getLocalePrice(finalPrice),
    };
  };
  const normalizedLabel =
    currentDiscount.recurrence > 1
      ? `*Válido por ${currentDiscount.recurrence} pagamentos*`
      : `*Válido por ${currentDiscount.recurrence} pagamento*`;
  return (
    <Stack data-testid="retentionDetailsModal">
      <Typography
        sx={{
          color: 'secondary.main',
          fontSize: 16,
          fontWeight: 'bold',
          position: 'absolute',
          top: 27,
        }}
      >
        {subscription?.title}
      </Typography>
      <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
        {subscription?.description}
      </Typography>
      <Stack gap={3} sx={{ my: 4 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            Período:
          </Typography>
          <Typography sx={{ color: 'grey.400' }}>
            {getPlanPeriodName()}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            Cartão ativo:
          </Typography>
          <Typography>Final {card.data}</Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            Valor do plano:
          </Typography>
          <Typography>{getLocalePrice(subscription?.price)}</Typography>
        </Stack>
      </Stack>
      <Accordion
        disableGutters
        expanded={accordeonExpanded}
        sx={{
          border: 'none',
          boxShadow: 'none',
          bgcolor: 'common.white',
          mb: 5,
          mt: 4,
        }}
        onChange={() => setAccordeonExpanded(!accordeonExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography sx={{ color: 'grey.300', fontSize: 16 }}>
              Total mensal:
            </Typography>
            <Typography
              sx={{ color: 'grey.400', textDecoration: 'line-through' }}
            >
              {getLocalePrice(subscription?.price)}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordeonItems
          primaryText={getDiscountLabel().label}
          secondaryText={getDiscountLabel().discountValue}
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 6 }}
        >
          <Typography sx={{ color: 'grey.500', fontSize: 14, fontWeight: 600 }}>
            Total agora:
          </Typography>
          <Typography
            sx={{ color: 'secondary.main', fontSize: 16, fontWeight: 600 }}
          >
            {getDiscountLabel().finalValue}
          </Typography>
        </Stack>
      </Accordion>
      <Button
        data-testid="resubscribeButton"
        onClick={assignHandler}
        sx={{ height: 60, width: '100%' }}
      >
        Aceitar desconto
      </Button>
      <Typography
        sx={{ color: 'grey.300', fontSize: 10, mt: 3, textAlign: 'center' }}
      >
        {normalizedLabel}
      </Typography>
    </Stack>
  );
};

export default RetentionDetails;
