import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Radio,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { CardNumberVerification } from 'card-validator/dist/card-number';
import creditCardValidator from 'card-validator';
import { CpfMaskInput } from 'modules/auth/pages/Register/components/CreditCardStep';
import cardsImgMapper from 'modules/auth/pages/Register/providers/cards';
import { CardType, PaymentStatus } from 'modules/auth/pages/Register/typings';
import { useContext, useState } from 'react';
import preventFloatingNumbers from 'utils/number';
import { useDialog } from 'components/Dialog';
import { useQuery } from 'react-query';
import { Plan } from 'interfaces/plan';
import MaskedCreditCardNumberInput from 'modules/account/pages/Payment/MaskedCreditCardNumberInput';
import { getPlans } from 'modules/account/pages/Subscriptions/services/getPlans';
import { LoadingButton } from '@material-ui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { validationSchema } from './form';
import { CvvMask, ExpirationDateInput } from './utils/Masks';
import FeedbackModal from './FeedbackModal';
import { changePaymentMethod } from './services/changePaymentMethod';
import UnrenewedSubscriptionsModal from './components/UnrenewedSubscriptionsModal';
import { getSubscriptionStatus } from './services/getSubscriptionStatus';
import Context from '../../Context';

interface PaymentFormProps {
  userSubscriptions?: Plan[];
  setUserIsSuspended?: (isFirstAccess: boolean) => void;
}

interface SubscriptionResponse {
  status: PaymentStatus;
  message: string;
}

const PaymentForm = ({
  userSubscriptions,
  setUserIsSuspended,
}: PaymentFormProps) => {
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const { profile, isLoadingUser } = useContext(Context);
  const { isLoading: loadingPlans, data: plans } = useQuery('plans', () =>
    getPlans(),
  );
  const filteredPlans = plans?.data?.filter((plan: Plan) =>
    userSubscriptions?.some(
      (subscription: Plan) => subscription.id === plan.id,
    ),
  );
  const hasPaymentType = (paymentType: string) =>
    filteredPlans?.some((plan: Plan) => plan.paymentType.includes(paymentType));
  const userData = profile;
  const suspendedPlans = userSubscriptions?.filter(
    (plan: Plan) => plan.status === 'suspended',
  );
  const suspendedPlansIds = suspendedPlans?.map(
    (suspendedPlan: Plan) => suspendedPlan.id,
  );
  const suspendedPlansIdsConcatenated = suspendedPlansIds?.join(',');
  const dialog = useDialog();
  const [cardDetails, setCardDetails] = useState<
    CardNumberVerification | undefined
  >();
  const [expanded, setExpanded] = useState<string | false>('painel1');
  const form = useFormik({
    initialValues: {
      email: userData?.email,
      paymentMethod: 'credit',
      coupon: '',
      cardHolderName: '',
      creditCard: '',
      expirationDate: '',
      cvv: '',
      cpf: '',
      debitCardHolderName: '',
      debitCard: '',
      debitExpirationDate: '',
      debitCVV: '',
      debitCPF: '',
      installments: 1,
    },
    validationSchema,
    onSubmit: async (formValues) => {
      const isPaymentByCredit = formValues.paymentMethod === 'credit';
      const values = {
        planIds: suspendedPlansIdsConcatenated,
        email: formValues.email,
        paymentMethod: formValues.paymentMethod,
        coupon: formValues.coupon,
        cardHolderName: isPaymentByCredit
          ? formValues.cardHolderName
          : formValues.debitCardHolderName,
        creditCard: isPaymentByCredit
          ? formValues.creditCard
          : formValues.debitCard,
        expirationDate: isPaymentByCredit
          ? formValues.expirationDate
          : formValues.debitExpirationDate,
        cvv: isPaymentByCredit ? formValues.cvv : formValues.debitCVV,
        cpf: isPaymentByCredit ? formValues.cpf : formValues.debitCPF,
        installments: 1,
      };
      try {
        setRequestIsLoading(true);
        dialog.setClosable(false);
        const response = await changePaymentMethod(values);
        const interval = setInterval(async () => {
          const { data: subscriptionsData } = await getSubscriptionStatus(
            response.data.message,
          );
          const subscriptionStatus = subscriptionsData
            ?.map((subscription: SubscriptionResponse) => subscription.status)
            .flat();
          const regularizedSubscriptions = subscriptionsData?.filter(
            (subscription: SubscriptionResponse) =>
              subscription.status === PaymentStatus.Success,
          );
          const irregularSubscriptions = subscriptionsData?.filter(
            (subscription: SubscriptionResponse) =>
              subscription.status !== PaymentStatus.Success &&
              subscription.status !== PaymentStatus.Awaiting,
          );
          const awaitingSubscriptions = subscriptionsData?.filter(
            (subscription: SubscriptionResponse) =>
              subscription.status === PaymentStatus.Awaiting,
          );
          const allPaymentSucced =
            subscriptionStatus.length === regularizedSubscriptions?.length;
          const allPaymentFailed =
            subscriptionStatus.length === irregularSubscriptions?.length;
          const hasNotAwatingSubscriptions = awaitingSubscriptions.length === 0;
          const hasErrorAndSuccessPayment =
            irregularSubscriptions.length > 0 &&
            regularizedSubscriptions.length > 0;
          if (hasNotAwatingSubscriptions) {
            if (allPaymentSucced) {
              clearInterval(interval);
              setRequestIsLoading(false);
              await dialog.open({
                element: (
                  <FeedbackModal
                    approved
                    setUserIsSuspended={setUserIsSuspended}
                  />
                ),
                sx: {
                  bgcolor: 'common.white',
                  maxWidth: { xs: '100%', lg: 375 },
                  width: '100%',
                },
              });
            } else if (allPaymentFailed) {
              clearInterval(interval);
              setRequestIsLoading(false);
              await dialog.open({
                element: <FeedbackModal />,
                sx: {
                  bgcolor: 'common.white',
                  maxWidth: { xs: '100%', lg: 375 },
                  width: '100%',
                },
              });
            } else if (hasErrorAndSuccessPayment) {
              clearInterval(interval);
              setRequestIsLoading(false);
              await dialog.open({
                element: (
                  <UnrenewedSubscriptionsModal
                    userSubscriptions={userSubscriptions}
                    regularizedSubscriptions={regularizedSubscriptions}
                    irregularSubscriptions={irregularSubscriptions}
                  />
                ),
                sx: { bgcolor: 'common.white', maxWidth: 375, width: '100%' },
              });
            }
          }
        }, 10000);
      } catch {
        await dialog.open({
          element: <FeedbackModal />,
          sx: {
            bgcolor: 'common.white',
            maxWidth: { xs: '100%', lg: 375 },
            width: '100%',
          },
        });
      }
    },
  });
  const accordionSwitch = (field: string, painel: string) => {
    form.setFieldValue('paymentMethod', field);
    setExpanded(painel);
  };
  if (isLoadingUser || loadingPlans)
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: 400, width: '100%' }}
      >
        <CircularProgress color="secondary" size="50px" sx={{ mb: 5, mr: 2 }} />
        <Typography sx={{ color: 'grey.500', fontSize: 17 }}>
          Aguarde um instante...
        </Typography>
      </Stack>
    );
  return (
    <PerfectScrollbar style={{ height: '67vh', paddingRight: '10px' }}>
      <Typography
        sx={{ color: 'grey.400', fontFamily: 'primary', fontSize: 14 }}
      >
        Forma de Pagamento
      </Typography>
      {hasPaymentType('credit') && (
        <Accordion
          expanded={expanded === 'painel1'}
          sx={{
            bgcolor: 'common.white',
            border: expanded ? 'none' : '1px solid',
            boxShadow: 'none',
            borderColor: 'primary.light',
            borderRadius: 1,
            mb: 2,
          }}
          onChange={() => accordionSwitch('credit', 'painel1')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Radio
                checked={form.values.paymentMethod === 'credit'}
                value="credit"
                onChange={(e) =>
                  form.setFieldValue('paymentMethod', e.target.value)
                }
              />
              <Typography
                sx={{
                  color: 'grey.400',
                  fontFamily: 'primary',
                  fontSize: 14,
                }}
              >
                Cartão de Crédito
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography
                sx={{
                  color: 'grey.400',
                  fontFamily: 'primary',
                  fontSize: 14,
                  mt: 5,
                }}
              >
                Nome do Titular:
              </Typography>
              <TextField
                data-testid="name-input"
                name="cardHolderName"
                placeholder="Digite o seu nome"
                error={
                  form.touched.cardHolderName && !!form.errors.cardHolderName
                }
                helperText={
                  form.touched.cardHolderName && form.errors.cardHolderName
                }
                fullWidth
                value={form.values.cardHolderName}
                sx={{
                  mt: 2,
                  width: '100%',
                  '.MuiFormHelperText-root': { ml: 0 },
                }}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              <Typography
                sx={{
                  color: 'grey.400',
                  fontFamily: 'primary',
                  fontSize: 14,
                  mt: 5,
                }}
              >
                Número do cartão:
              </Typography>
              <TextField
                data-testid="creditCardInput"
                error={form.touched.creditCard && !!form.errors.creditCard}
                helperText={form.touched.creditCard && form.errors.creditCard}
                name="creditCard"
                type="tel"
                placeholder="0000-0000-0000-0000"
                value={form.values.creditCard}
                InputProps={{
                  endAdornment: cardDetails?.card && (
                    <SvgIcon
                      component={
                        cardsImgMapper[cardDetails!.card!.type as CardType]
                      }
                      viewBox="0 0 780 500"
                      sx={{ height: 19, width: 29 }}
                    />
                  ),
                  inputComponent: MaskedCreditCardNumberInput,
                }}
                sx={{
                  mt: 2,
                  width: '100%',
                  '.MuiFormHelperText-root': { ml: 0 },
                }}
                onBlur={form.handleBlur}
                onChange={(evt) => {
                  form.handleChange(evt);
                  setCardDetails(
                    creditCardValidator.number(form.values.creditCard),
                  );
                }}
              />
            </Box>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: 'grey.400',
                    fontFamily: 'primary',
                    fontSize: 14,
                  }}
                >
                  Validade:
                </Typography>
                <TextField
                  data-testid="expirationDateInput"
                  value={form.values.expirationDate.replaceAll(' ', '')}
                  name="expirationDate"
                  type="tel"
                  error={
                    form.touched.expirationDate && !!form.errors.expirationDate
                  }
                  helperText={
                    form.touched.expirationDate && form.errors.expirationDate
                  }
                  fullWidth
                  InputProps={{ inputComponent: ExpirationDateInput }}
                  placeholder="MM/AA"
                  sx={{
                    mt: 2,
                    width: '100%',
                    '.MuiFormHelperText-root': { ml: 0 },
                  }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: 'grey.400',
                    fontFamily: 'primary',
                    fontSize: 14,
                  }}
                >
                  CVV:
                </Typography>
                <TextField
                  data-testid="cvvInput"
                  error={form.touched.cvv && !!form.errors.cvv}
                  helperText={form.touched.cvv && form.errors.cvv}
                  name="cvv"
                  placeholder="000"
                  value={form.values.cvv}
                  sx={{
                    mt: 2,
                    width: '100%',
                  }}
                  InputProps={{ inputComponent: CvvMask }}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) =>
                    preventFloatingNumbers(e, ['E', 'e', '-', '+', ',', '.'])
                  }
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: 'grey.400',
                    fontFamily: 'primary',
                    fontSize: 14,
                  }}
                >
                  CPF do titular:
                </Typography>
                <TextField
                  data-testid="cpfInput"
                  name="cpf"
                  type="tel"
                  error={form.touched.cpf && !!form.errors.cpf}
                  helperText={form.touched.cpf && form.errors.cpf}
                  InputProps={{ inputComponent: CpfMaskInput }}
                  placeholder="000.000.000-00"
                  value={form.values.cpf}
                  sx={{
                    mt: 2,
                    width: '100%',
                    '.MuiFormHelperText-root': { ml: 0 },
                  }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      <LoadingButton
        loading={requestIsLoading}
        fullWidth
        sx={{ my: 2, py: 5 }}
        onClick={() => form.handleSubmit()}
      >
        Confirmar alteração
      </LoadingButton>
    </PerfectScrollbar>
  );
};

export default PaymentForm;
