import { State } from 'history';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { getSubscriptions } from './services';
import CustomPage from './CustomPage';
import PagePlaceholder from './PagePlaceholder';
import LoadingPage from './placeholders/LoadingPage';
import { Page, PageType } from './typings';
import { getPageTypeByPathname } from './utils';
import { Plan } from '../../interfaces/plan';

type PageState = State & { subscriptionId: string };

const CustomPlanPage = () => {
  const [subscription, setSubscription] = useState<Plan>();
  const [pageId, setPageId] = useState('');
  const [pagePlaceholder, setPagePlaceholder] = useState<PageType>();
  const location = useLocation();
  const state = location.state as PageState;
  const pathMatch = useMatch('/plan/:type');
  const navigate = useNavigate();
  const { subscriptionId } = state || { subscriptionId: '0' };
  useEffect(() => {
    if (!subscriptionId) {
      navigate('/404');
    }
  }, [subscriptionId]);
  useEffect(() => {
    const pageTypeByPathname = getPageTypeByPathname(
      pathMatch!.params.type,
    ) as PageType;
    setPagePlaceholder(pageTypeByPathname);
  }, [pathMatch!.params.type]);
  const { isLoading } = useQuery('subscriptions', getSubscriptions, {
    onSuccess: (data) => {
      const pageTypeByPathname = getPageTypeByPathname(
        pathMatch!.params.type,
      ) as PageType;
      if (!data.data[0]) return;
      const currentSubscription = data.data.find(
        (subscriptionItem: Plan) => subscriptionItem.id === subscriptionId,
      );
      if (!currentSubscription) {
        return;
      }
      setSubscription(currentSubscription);
      const pagesList = currentSubscription.pages || [];
      const pageFound = pagesList.find(
        (planPage: Page) => planPage.type === pageTypeByPathname,
      );
      if (pageFound) {
        setPageId(pageFound.id);
      }
    },
    onError: () => {
      const pageTypeByPathname = getPageTypeByPathname(
        pathMatch!.params.type,
      ) as PageType;
      setPagePlaceholder(pageTypeByPathname);
    },
  });
  if (isLoading) return <LoadingPage height="100vh" />;
  if (pageId && !pagePlaceholder) return <CustomPage pageId={pageId} />;
  return (
    <PagePlaceholder pageType={pagePlaceholder!} subscription={subscription} />
  );
};

export default CustomPlanPage;
