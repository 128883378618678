import { Stack, useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CustomPage from 'modules/pages/CustomPage';
import Editvatar from 'modules/account/pages/Editvatar';
import CustomPlanPage from 'modules/pages/CustomPlanPage';
import TournamentsTable from 'modules/tournaments-table/TournamentsTable';
import ReactivateSuspended from 'modules/reactvate-suspended/ReactivateSuspended';
import { useQuery } from 'react-query';
import getUserProfile from 'modules/account/pages/Profile/services/getUserProfile';
import getUserPlans from 'modules/account/pages/Subscriptions/services/getUserPlans';
import Home from 'modules/home/refactor/Home';
import useDynamicImport from 'utils/hooks/use-dynamic-import';
import useIsOnTop from 'utils/hooks/use-is-on-top';
import SimpleBar from 'simplebar-react';
import LiveContents from 'modules/live-section';
import FavoriteTeamSection from 'modules/home/refactor/components/TeamSection';
import Account from '../modules/account';
import MyAccountSection from '../modules/account/components/MyAccountSection';
import Content from '../modules/content';
import Section from '../modules/home/components/SelectedSection';
import Notifications from '../modules/notifications/Notifications';
import Search from '../modules/search';
import Header from './Header';
import SectionContent from './SectionContent';
import Context from '../Context';
import environment from '../environment.config';

const AppTemplate = () => {
  const { loading: isLoadingBackground, element: background } =
    useDynamicImport(`${environment.AppSlug}/jpg/background.jpg`);
  const {
    isLoading: isLoadingUser,
    data: userProfile,
    refetch,
  } = useQuery('userProfile', getUserProfile);
  const { isLoading, data: userPlans } = useQuery(
    'userSubscriptions',
    getUserPlans,
  );
  const profile = userProfile?.data;
  const filteredSupendedPlans =
    userPlans?.data.filter((plan) => plan.status === 'suspended') || [];
  const hasSuspendedPlans =
    filteredSupendedPlans && filteredSupendedPlans?.length > 0;
  const [userIsSuspended, setUserIsSuspended] = useState(hasSuspendedPlans);
  useEffect(() => {
    if (hasSuspendedPlans) {
      setUserIsSuspended(true);
    }
  }, [hasSuspendedPlans]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const headerRef = useRef(null);
  const { pathname } = useLocation();
  const isHomePage = pathname === '/home';
  const isSectionContentScreen = pathname.includes('/section-content');
  const isHomeOrContentScreen = isHomePage || isSectionContentScreen;
  const simpleBarRef = useRef<SimpleBar>(null);
  const scrollableNodeRef = useRef(null);
  const [isFirstAccess, setIsFirstAccess] = useState(true);
  const baseHeaderHeight = '70px';
  const showModalOfBlocked = userIsSuspended && isFirstAccess;
  const isOnTop = useIsOnTop(scrollableNodeRef);
  useEffect(() => {
    simpleBarRef.current?.el.scrollIntoView();
  }, [pathname, simpleBarRef]);
  // TODO: add skeleton
  if (isLoading || !userPlans || isLoadingUser || isLoadingBackground)
    return <div>Carregando...</div>;
  return (
    <Context.Provider value={{ profile, refetch, isLoadingUser }}>
      <Header headerRef={headerRef} isOnTop={isOnTop} isLogged />
      <SimpleBar
        id="container"
        ref={simpleBarRef}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        style={{
          backgroundImage: showModalOfBlocked ? '' : `url(${background})`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          backgroundColor: showModalOfBlocked ? 'black' : 'transparent',
          height: '100vh',
          marginBottom: isMobile ? 60 : 30,
        }}
      >
        <Stack
          sx={{
            pt: {
              xs: baseHeaderHeight,
              lg: isHomeOrContentScreen ? 0 : baseHeaderHeight,
            },
          }}
        >
          {showModalOfBlocked ? (
            <ReactivateSuspended
              filteredSupendedPlans={filteredSupendedPlans}
              setIsFirstAccess={setIsFirstAccess}
              setUserIsSuspended={setUserIsSuspended}
            />
          ) : (
            <Routes>
              <Route path="/account" element={<Account />} />
              <Route path="/account/*" element={<MyAccountSection />} />
              <Route path="/avatar/edit" element={<Editvatar />} />
              <Route path="/content/:id" element={<Content />} />
              <Route path="/page*" element={<CustomPage />} />
              <Route path="/plan*" element={<CustomPlanPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/live-contents" element={<LiveContents />} />
              <Route path="/team-content" element={<FavoriteTeamSection />} />
              <Route path="/section-content/:id" element={<SectionContent />} />
              <Route path="/tournament/table" element={<TournamentsTable />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/search" element={<Search />} />
              <Route path="/section/:id" element={<Section />} />
              <Route element={<Navigate to="/home" />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          )}
        </Stack>
      </SimpleBar>
    </Context.Provider>
  );
};

export default AppTemplate;
